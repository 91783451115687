import { useService } from '@xstate/react';
import type { State, PayloadSender } from 'xstate';
import type {
  Context,
  Events,
  StateSchema,
  Typestate,
} from '../machines/newPage/types';

import dynamic from '../utils/dynamic';
import { CenteredLoading } from '@zapier/transfer-components';

import { NewPageController as NewPageControllerType } from './types';

const Welcome = dynamic(() => import('./Welcome'));
const AppPickerContainer = dynamic(() => import('./AppPicker'));
const GenericError = dynamic(() => import('./GenericError'));

const getButtonData = (
  current: State<Context, Events, StateSchema, Typestate>,
  send: PayloadSender<Events>
) => {
  const isDisabled =
    current.matches('auth') ||
    current.matches({ welcome: { hydrate: 'fetch' } });
  const isLoggedIn = current.context.session !== undefined;
  return isLoggedIn
    ? {
        isDisabled,
        text: 'Create a new transfer',
        onClick: () => send('CONTINUE'),
      }
    : {
        isDisabled,
        text: 'Login',
        onClick: () => send('CONTINUE'),
      };
};

const NewPageController: NewPageControllerType = ({ service }) => {
  const [current, send] = useService(service);
  const { isDisabled, text, onClick } = getButtonData(current, send);

  switch (true) {
    case current.matches({ createZap: 'error' }):
      return (
        <GenericError
          message="There was a problem creating the Transfer."
          name="Transfer creation error"
          onClickBackButton={() => send('BACK')}
          onClickRetryButton={() => send('RETRY')}
        />
      );

    case current.matches({ welcome: { hydrate: 'error' } }):
      return (
        <GenericError
          message="There was a problem loading the app data."
          name="Data error"
          onClickRetryButton={() => send('RETRY')}
        />
      );

    case current.matches('auth'):
    case current.matches({ createZap: 'create' }):
    case current.matches('done'):
    case current.matches('welcome') && current.context.skipWelcomeScreen:
      return <CenteredLoading />;

    case current.matches('welcome'):
      return (
        <Welcome
          buttonText={text}
          failedToLoadPrivateAppsWarning={
            current.context.failedToLoadPrivateAppsWarning
          }
          isDisabled={isDisabled}
          onClick={onClick}
        />
      );

    case current.matches('select'):
    case current.matches('selectReady'):
      return <AppPickerContainer service={service} />;

    default:
      throw new Error(`Unhandled state: ${current.toStrings().join(', ')}`);
  }
};

export default NewPageController;
