import { assign, MachineOptions } from 'xstate';
import { concat } from 'lodash';

import { navigateToEdit } from '../shared/actions/navigate';
import { Context, EventOfType } from './types';

type MachineActions = MachineOptions<Context, any>['actions'];

const actions: MachineActions = {
  navigateToEdit,

  setSession: assign<Context, EventOfType<'LOGGED_IN'>>({
    session: (_, { data }) => data,
  }),

  setReadApps: assign<Context, EventOfType<'done.invoke.fetchApps'>>({
    readApps: ({ readApps }, { data }) => concat(readApps, data.readApps),
  }),

  setWriteApps: assign<Context, EventOfType<'done.invoke.fetchApps'>>({
    searchableWriteApps: ({ searchableWriteApps }, { data }) =>
      concat(searchableWriteApps, data.searchableWriteApps),
  }),

  setReadApp: assign<Context, EventOfType<'SELECT_READ_APP'>>({
    selectedReadAppId: (_, { data }) => data,
    selectedReadAction: (_) => undefined,
    readActions: (_) => [],
  }),

  /**
   * In order to prevent the app picker dropdown from rendering
   * before we have the initial app data available, we'll start
   * the read machine in an `init` state instead of `idle`. It'll
   * transition from `init` to `implementation`, then to `idle`,
   * invoking this action on entry.
   *
   * TODO: rip out this totally unnecessary variable and
   * compare against the machine state instead.
   */
  markReadIdled: assign({
    readIdled: (_) => true,
  }),

  /**
   * In order to prevent the app picker dropdown from rendering
   * before we have the initial app data available, we'll start
   * the write machine in an `init` state instead of `idle`. It'll
   * transition from `init` to `implementation`, then to `idle`,
   * invoking this action on entry.
   *
   * TODO: rip out this totally unnecessary variable and
   * compare against the machine state instead.
   */
  markWriteIdled: assign({
    writeIdled: (_) => true,
  }),

  setReadActions: assign<
    Context,
    EventOfType<'done.invoke.fetchReadImplementation'>
  >((_, { data }) => {
    const readActions = (data?.actions || []).filter(
      (a) => !a.is_hidden && a.key.slice(0, 8).toLowerCase() !== 'updated_'
    );
    return {
      readActions,
      selectedReadAction: readActions.length === 1 ? readActions[0] : undefined,
    };
  }),

  setReadError: assign({
    readError: (_) => 'Unknown error fetching read actions',
  }),

  setReadAction: assign<Context, EventOfType<'SELECT_READ_ACTION'>>({
    selectedReadAction: (_, { data }) => data,
  }),

  setWriteApp: assign<Context, EventOfType<'SELECT_WRITE_APP'>>({
    selectedWriteAppId: (_, { data }) => data,
    selectedWriteAction: (_) => undefined,
    writeActions: (_) => [],
  }),

  setWriteActions: assign<
    Context,
    EventOfType<'done.invoke.fetchWriteImplementation'>
  >((_, { data }) => {
    // No need to filter actions here because they will be filtered by the useVisibleActions() hook
    const writeActions = data?.actions || [];
    return {
      writeActions,
      selectedWriteAction:
        writeActions.length === 1 ? writeActions[0] : undefined,
    };
  }),

  setWriteError: assign({
    writeError: (_) => 'Unknown error fetching write actions',
  }),

  setWriteAction: assign<Context, EventOfType<'SELECT_WRITE_ACTION'>>({
    selectedWriteAction: (_, { data }) => data,
  }),

  setZapId: assign<Context, EventOfType<'done.invoke.createZap'>>({
    zapId: (_, { data }) => data,
  }),

  setCreateZapError: assign<Context, EventOfType<'error.platform.createZap'>>({
    createZapError: (_) => 'There was a problem creating the Zap.',
  }),
};

export default actions;
