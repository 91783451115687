import { useRouter } from 'next/router';
import { createMachine } from 'xstate';

import { useMachineWithBreadcrumbs } from '../../utils/useMachineWithBreadcrumbs';
import { useAppContext } from '../../context/appContext';
import { config } from './config';
import { DEBUG_ENABLED } from '../../constants';
import { useTracking } from '../../observability';
import services from './services';
import guards from './guards';
import actions from './actions';
import track from './track';

export const useNewPageMachine = () => {
  const { query } = useRouter();
  const appService = useAppContext();
  const tracking = useTracking();

  const machine = createMachine(config, {
    services: services(appService!),
    guards,
    actions: {
      ...actions,
      track: track.bind(null, tracking),
    },
  });

  return useMachineWithBreadcrumbs(machine, {
    devTools: DEBUG_ENABLED,
    context: {
      skipWelcomeScreen: query.view === 'apps',
      isRecurringTransfer: !!query.recurring,
    },
  });
};
