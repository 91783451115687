import { StateNodeConfig, actions } from 'xstate';

import { Context, EventOfType, Events, StateSchema } from './types';
import {
  SendExprReturningString,
  SendExprReturningStringOrNull,
  track,
  TrackingAction,
} from '../shared/actions/track';

const { pure } = actions;

const trackNew = <Event = any>(
  action: TrackingAction,
  name: string,
  label: string,
  goal: string | SendExprReturningString<Context, Event> = 'see screen',
  option?: SendExprReturningStringOrNull<Context, Event>,
  referenceId?: SendExprReturningString<Context, Event>
) => track<Context>(action, name, label, goal, option, referenceId);

export const config: StateNodeConfig<Context, StateSchema, Events> = {
  strict: true,
  id: 'newPage',
  initial: 'auth',

  context: {
    readApps: [],
    readIdled: false,
    writeIdled: false,
    readActions: [],
    writeActions: [],
    skipWelcomeScreen: false,
    isRecurringTransfer: false,
    searchableWriteApps: [],
  },

  states: {
    auth: {
      invoke: {
        id: 'session',
        src: 'session',
      },
      on: {
        LOGGED_IN: {
          target: 'welcome',
          actions: [
            'setSession',
            pure(({ isRecurringTransfer }) =>
              isRecurringTransfer
                ? trackNew<EventOfType<'LOGGED_IN'>>(
                    'click',
                    'SelectRecurringTransfer',
                    'Action: Get Started',
                    'go to Scheduled-Transfer'
                  )
                : trackNew<EventOfType<'LOGGED_IN'>>(
                    'click',
                    'SelectOneTimeTransfer',
                    'Action: Get Started',
                    'go to One-time-Transfer'
                  )
            ),
            trackNew<EventOfType<'LOGGED_IN'>>(
              'enter',
              'Welcome',
              'Screen: Coming Up',
              'see screen'
            ),
          ],
        },
      },
    },
    welcome: {
      type: 'parallel',
      states: {
        hydrate: {
          initial: 'fetch',
          states: {
            fetch: {
              invoke: {
                id: 'fetchApps',
                src: 'fetchApps',
                onDone: {
                  target: 'done',
                  actions: ['setReadApps', 'setWriteApps'],
                },
                onError: {
                  target: 'error',
                  actions: [
                    trackNew<EventOfType<'error.platform.fetchApps'>>(
                      'error',
                      'Welcome',
                      'Error: Hydrate Apps',
                      (_, { data }) => data.message,
                      () => null,
                      (_, { data }) => data.referenceId
                    ),
                  ],
                },
              },
            },
            error: {
              on: {
                RETRY: {
                  target: 'fetch',
                  actions: trackNew(
                    'click',
                    'WelcomeHydrateErrorRetryButton',
                    'Action: Retry',
                    'retry hydrate'
                  ),
                },
              },
            },
            done: { type: 'final' },
          },
        },
        wizard: {
          initial: 'pending',
          states: {
            pending: {
              always: [
                {
                  cond: 'shouldSkipWelcomeScreen',
                  target: 'done',
                },
              ],
              on: {
                CONTINUE: {
                  target: 'done',
                  actions: trackNew(
                    'click',
                    'WelcomeWizardContinueButton',
                    'Action: Continue',
                    'go to app picker'
                  ),
                },
              },
            },
            done: { type: 'final' },
          },
        },
      },
      onDone: [
        {
          target: 'select',
        },
      ],
    },
    select: {
      entry: trackNew(
        'enter',
        'AppPicker',
        'Screen: Select Apps',
        'see screen'
      ),
      type: 'parallel',
      invoke: {
        id: 'fetchPrivateWriteApps',
        src: 'fetchPrivateWriteApps',
        onDone: {
          actions: 'setWriteApps',
        },
      },
      on: {
        CREATE_ZAP: {
          target: '#newPage.createZap',
          actions: trackNew(
            'click',
            'AppPickerNextButton',
            'Action: Next',
            'create zap'
          ),
        },
        BACK: {
          target: '#newPage.welcome',
          actions: trackNew(
            'click',
            'AppPickerBackButton',
            'Action: Back',
            'back to welcome'
          ),
        },
      },
      states: {
        read: {
          initial: 'init',
          states: {
            init: {
              always: [
                {
                  target: 'implementation',
                  cond: 'hasReadAppIdInContext',
                },
                {
                  target: 'idle',
                },
              ],
            },
            idle: {
              entry: 'markReadIdled',
              on: {
                SELECT_READ_APP: {
                  target: 'implementation',
                  actions: [
                    'setReadApp',
                    trackNew<EventOfType<'SELECT_READ_APP'>>(
                      'select',
                      'AppPickerReadAppDropdown',
                      'Action: Source App',
                      'read app selected',
                      (_, { data }) => data || ''
                    ),
                  ],
                },
                SELECT_READ_ACTION: {
                  actions: [
                    'setReadAction',
                    trackNew<EventOfType<'SELECT_READ_ACTION'>>(
                      'select',
                      'AppPickerReadActionDropdown',
                      'Action: Source Data',
                      'read action selected',
                      (_, { data }) => data?.key || ''
                    ),
                  ],
                },
              },
            },
            implementation: {
              initial: 'fetch',
              states: {
                fetch: {
                  invoke: {
                    id: 'fetchReadImplementation',
                    src: 'fetchReadImplementation',
                    onDone: {
                      target: '#newPage.select.read.idle',
                      actions: 'setReadActions',
                    },
                    onError: {
                      target: 'error',
                      actions: 'setReadError',
                    },
                  },
                },
                error: {
                  on: {
                    RETRY: {
                      target: 'fetch',
                      actions: trackNew(
                        'click',
                        'AppPickerFetchReadImplementationErrorRetryButton',
                        'Action: Retry',
                        'retry fetch read implementation'
                      ),
                    },
                  },
                },
              },
            },
            selected: { type: 'final' },
          },
        },
        write: {
          initial: 'init',
          states: {
            init: {
              always: [
                {
                  target: 'implementation',
                  cond: 'hasWriteAppIdInContext',
                },
                {
                  target: 'idle',
                },
              ],
            },
            idle: {
              entry: 'markWriteIdled',
              on: {
                SELECT_WRITE_APP: {
                  target: 'implementation',
                  actions: [
                    'setWriteApp',
                    trackNew<EventOfType<'SELECT_WRITE_APP'>>(
                      'select',
                      'AppPickerWriteAppDropdown',
                      'Action: Destination App',
                      'write app selected',
                      (_, { data }) => data || ''
                    ),
                  ],
                },

                SELECT_WRITE_ACTION: {
                  actions: [
                    'setWriteAction',
                    trackNew<EventOfType<'SELECT_WRITE_ACTION'>>(
                      'select',
                      'AppPickerWriteActionDropdown',
                      'Action: Destination Action',
                      'write action selected',
                      (_, { data }) => data?.key || ''
                    ),
                  ],
                },
              },
            },
            implementation: {
              initial: 'fetch',
              states: {
                fetch: {
                  invoke: {
                    id: 'fetchWriteImplementation',
                    src: 'fetchWriteImplementation',
                    onDone: {
                      target: '#newPage.select.write.idle',
                      actions: 'setWriteActions',
                    },
                    onError: {
                      target: 'error',
                      actions: 'setWriteError',
                    },
                  },
                },
                error: {
                  on: {
                    RETRY: {
                      target: 'fetch',
                      actions: trackNew(
                        'click',
                        'AppPickerFetchWriteImplementationErrorRetryButton',
                        'Action: Retry',
                        'retry fetch write implementation'
                      ),
                    },
                  },
                },
              },
            },
            selected: { type: 'final' },
          },
        },
      },
      onDone: 'createZap',
    },
    createZap: {
      initial: 'create',
      states: {
        create: {
          invoke: {
            id: 'createZap',
            src: 'createZap',
            onDone: {
              target: '#newPage.done',
              actions: 'setZapId',
            },
            onError: [
              {
                target: '#newPage.welcome',
                cond: 'hasNotIdled',
              },
              {
                target: 'error',
                actions: 'setCreateZapError',
              },
            ],
          },
        },
        error: {
          on: {
            RETRY: {
              target: 'create',
              actions: trackNew(
                'click',
                'AppPickerCreateZapErrorRetryButton',
                'Action: Retry',
                'retry create zap'
              ),
            },
          },
        },
      },
    },
    done: {
      type: 'final',
      entry: 'navigateToEdit',
    },
    fatalError: { type: 'final' },
  },
  on: {
    TRACK: {
      actions: 'track',
    },
  },
};
