import Head from 'next/head';
import { Fragment, FC } from 'react';
import { NextPage } from 'next';

import useNewPageMachine from '../machines/newPage';
import NewPageController from '../controllers/NewPage';
import RouterReady from '../components/RouterReady';

const Main: FC = () => {
  const [, , service] = useNewPageMachine();
  return <NewPageController service={service} />;
};

const NewPage: NextPage = () => (
  <Fragment>
    <Head>
      <title key="title">Zapier Transfer</title>
      <meta
        content="Move critical data where you need it, when you need it, with Transfer by Zapier: the simplest way to move bulk data on-demand"
        key="description"
        name="description"
      />
    </Head>
    <RouterReady when={({ isReady }) => isReady}>
      <Main />
    </RouterReady>
  </Fragment>
);

export default NewPage;
