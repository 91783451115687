import type { TrackingFunction } from '../../observability/tracking/tracking';
import type { TrackingEvent } from '../shared/actions/track';
import { Context } from './types';

const track = (trackEvent: TrackingFunction, context: Context, _event: any) => {
  const { action, name, label, goal, option, referenceId } =
    _event as TrackingEvent;
  trackEvent(
    {
      event_action: action,
      interaction_goal: goal,
      interaction_name: name,
      interaction_raw_text: label,
      option_selected: option,
      scheduled_transfer_flag: context.isRecurringTransfer,
    },
    {
      account_id: context.session?.currentAccountId || null,
      client_error_event_reference_id: referenceId,
      read_app_selected_api: context.selectedReadAppId || null,
      read_action_key: context.selectedReadAction?.key || null,
      read_action_type: context.selectedReadAction?.type || null,
      write_app_selected_api: context.selectedWriteAppId || null,
      write_action_key: context.selectedWriteAction?.key || null,
      write_action_type: context.selectedWriteAction?.type || null,
    }
  );
};

export default track;
