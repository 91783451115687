import { AppMetadata, AppMetadataWithSearch } from '@zapier/transfer-client';
import { deburr, toLower, map } from 'lodash';

const addSearchToAppMetadata = (app: AppMetadata): AppMetadataWithSearch => {
  const { name, categories } = app;

  // Changes 'Be (1.0.0)' to 'Be' for more accurate search results (EDITOR-3752)
  const searchName = deburr(toLower(name || ''))
    .replace(/\((?:\d{1,2}\.){2}\d{1,3}\)/gm, '')
    .trim();
  const searchCategory = toLower(map(categories, 'name').join(' ') || '');
  const isByZapier = searchName.includes('by zapier');

  return {
    ...app,
    searchName,
    searchCategory,
    isByZapier,
  };
};

export default addSearchToAppMetadata;
