import type { MachineOptions } from 'xstate';
import type {
  PaginatedResponse,
  Implementation,
} from '@zapier/transfer-client';
import { uniqBy } from 'lodash';

import { transferClient, zapierClient } from '../../utils/client';
import sessionService from '../shared/services/session';
import type { Service as AppService } from '../app/types';
import type { Context, Events } from './types';
import addSearchToAppMetadata from '../../utils/addSearchToAppMetadata';

type MachineServices = MachineOptions<Context, Events>['services'];

const services = (appService: AppService): MachineServices => ({
  session: sessionService(appService) as any,

  fetchApps: async (context) => {
    const [publicReadApps, privateReadApps, publicWriteApps] =
      await Promise.all([
        transferClient.getPublicTriggerApps({
          accountId: context.session?.currentAccountId,
        }),
        transferClient.getPrivateTriggerApps({
          accountId: context.session?.currentAccountId,
        }),
        zapierClient.getPublicApps(),
      ]);

    const allReadApps = uniqBy(publicReadApps.concat(privateReadApps), 'id');

    const searchableWriteApps = publicWriteApps.map(addSearchToAppMetadata);

    return {
      readApps: allReadApps,
      searchableWriteApps,
    };
  },

  fetchPrivateWriteApps: async () => {
    const privateWriteApps = await zapierClient.getPrivateApps();
    const searchableWriteApps = privateWriteApps.map(addSearchToAppMetadata);
    return { searchableWriteApps };
  },

  fetchReadImplementation: ({ session, selectedReadAppId }) =>
    transferClient.fetch<Implementation | undefined>(
      `/v1/trigger-implementation/?selected_api=${selectedReadAppId}`,
      {
        headers: {
          'x-account-id': String(session!.currentAccountId),
        },
      }
    ),

  fetchWriteImplementation: async ({ selectedWriteAppId }) => {
    if (!selectedWriteAppId) {
      return undefined;
    }

    const {
      results: [implementation],
    } = await zapierClient.fetch<PaginatedResponse<Implementation | undefined>>(
      `/api/v4/implementations/?selected_apis=${selectedWriteAppId}`
    );

    return implementation;
  },

  createZap: async (context) => {
    const { meta } = await zapierClient.getMetadata({
      readAppKey: context.selectedReadAppId!,
      readActionKey: context.selectedReadAction!.key,
      readActionType: context.selectedReadAction!.type,
      writeAppKey: context.selectedWriteAppId!,
      writeActionKey: context.selectedWriteAction!.key,
      writeActionType: context.selectedWriteAction!.type,
    });
    const zap = await zapierClient.createZap({
      accountId: context.session!.currentAccountId!,
      meta,
      isRecurring: !!context.isRecurringTransfer,
    });
    return zap.id;
  },
});

export default services;
