import { MachineOptions } from 'xstate';
import type { Context } from './types';

type MachineGuards = MachineOptions<Context, any>['guards'];

const guards: MachineGuards = {
  shouldSkipWelcomeScreen: ({ skipWelcomeScreen }) => !!skipWelcomeScreen,

  hasNotIdled: ({ readIdled, writeIdled }) => !readIdled || !writeIdled,

  hasReadAppIdInContext: ({ selectedReadAppId }) => !!selectedReadAppId,

  hasWriteAppIdInContext: ({ selectedWriteAppId }) => !!selectedWriteAppId,
};

export default guards;
